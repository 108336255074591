<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import { useStorage } from "vue3-storage";
import { verifyToken } from "@/api/auth-service";

export default {
  async mounted() {
    
    const useToken = useStorage();
    const token = useToken.getStorageSync('appToken');

    if(token === undefined) {
      this.$router.push({ name: 'admin.login'})
    }

    if(!await verifyToken(token)) {
        this.$router.push({ name: 'admin.login'})
    }
  }  
}
</script>